@import url('https://fonts.googleapis.com/css2?family=Lora');
@import url('https://fonts.googleapis.com/css2?family=IBM+Plex+Sans&display=swap');
@import url('https://fonts.googleapis.com/css?family=Cormorant%3A600%2C700%2C700i');
@import url('https://fonts.googleapis.com/css2?family=Source+Serif+Pro:ital@1&display=swap');

@import url('/assets/css/cheerup.css');
@import url('/assets/css/skin-fashion.css');
@import url('/assets/css/inline.css');
@import url('/assets/css/star-rating.css');


$theme-teal: #0c6572;

$theme-darkgold: #a18858;
$theme-gold: #c1aa55;
$theme-lightgold: #eddb68;

$theme-lightgray: #aaaaaa;
$theme-gray: #505050;

.custom-background {
    background-image: url('/assets/img/lightpaperfibers.png');
    background-position: left top;
    background-size: auto;
    background-repeat: repeat;
    background-attachment: scroll;
}

.gr_grid_book_container {
    text-align: center;
}

.logo-image {
    -webkit-filter: drop-shadow(4px 4px 8px #222);
    filter: drop-shadow(4px 4px 8px #222);
}

.logo-image:hover {
    -webkit-filter: drop-shadow(6px 6px 12px #333);
    filter: drop-shadow(6px 6px 12px #333);
}

small {
    font-size: .5em;
}

a:visited,
a:active,
a:link {
    color: $theme-teal;
}

a:hover,
a:focus {
    color: $theme-gold;
}

.post-content a:visited,
.post-content a:active,
.post-content a:link {
    color: $theme-teal;
    border-bottom: 1px dotted $theme-gold;
}

.post-content a:hover,
.post-content a:focus {
    color: $theme-gold;
    border-bottom: none;
}

.text-red {
    color: red;
}

footer .widget-about .image-circle {
    width: 50%;
    margin: 0 auto 25px;
}

// Overriding menu item padding so we can fit more items
.navigation .menu > li > a {
    padding: 0 15px;
}

.author-box {
    margin-top: 100px;
}


////////////////////////
// Pagination

.main-pagination {
    margin-top: 10px;
    margin-bottom: 10px;
}

.main-pagination .next {
    text-align: right;
}

.main-pagination .next a, 
.main-pagination .previous a {
    float: none;
}

.main-pagination .page_number {    
    padding-bottom: 3px;
    letter-spacing: 0.08em;
    text-transform: uppercase;
    font-size: 11px;
    font-weight: 600;
    border-bottom: 1px solid #ebebeb;
    text-align: center;
    margin: 0 auto;
}


////////////////////////
// Back to top button

#btnTop {
    display: none; /* Hidden by default */
    position: fixed; /* Fixed/sticky position */
    bottom: 20px; /* Place the button at the bottom of the page */
    right: 30px; /* Place the button 30px from the right */
    z-index: 99; /* Make sure it does not overlap */
    border: none; /* Remove borders */
    outline: none; /* Remove outline */
    background-color: $theme-teal; /* Set a background color */
    color: $theme-lightgold; /* Text color */
    cursor: pointer; /* Add a mouse pointer on hover */
    padding: 15px; /* Some padding */
    border-radius: 10px; /* Rounded corners */
    font-size: 18px; /* Increase font size */
}
  
#btnTop:hover {
    color: $theme-gold;
}


////////////////////////
// Book series

.book-series ul {
    display: flex;
    flex-flow: row wrap;
    margin-left: -2px; /* Adjustment for the gutter */
    width: 100%;
}

.book-series li {
    width: 200px;
    margin: 0 15px 15px 15px;
}

.widget_bookserieswidget li {
    width: 80px;
    // flex: auto;
    margin: 0 3px 5px 0; 
}


////////////////////////
// Books by author

.books-by-author {
    margin-top: 35px;
    border-top: 1px solid #ebebeb;
    padding-top: 25px;
}

.books-by-author h1 {
    text-align: center;
    color: $theme-teal;
    line-height: 20px;
    font-size: 20px;
    font-weight: 600;
    text-transform: uppercase;
    margin-bottom: 25px;
}

.books-by-author ul {
    display: flex;
    flex-flow: row wrap;
    margin-left: -8px; /* Adjustment for the gutter */
    width: 100%;
}

.books-by-author ul li {
    width: 180px;
    // flex: auto;
    margin: 0 8px 8px 0; /* Some gutter */
}

////////////////////////
// Spoiler tags

spoiler {
    background: #454545;
    border-radius: 0.2em;
    color: transparent;
    cursor: pointer;
}
spoiler:hover {
    background: $theme-lightgold;
    color: $theme-gray;
    border-bottom: 1px dotted $theme-gold;
    transition: background 0.3s ease 0.2s, color 0.2s ease 0.25s;
}

spoiler::before, spoiler::after {
    clip-path: inset(100%);
    clip: rect(1px, 1px, 1px, 1px);
    height: 1px;
    overflow: hidden;
    position: absolute;
    white-space: nowrap;
    width: 1px;
}
spoiler::before {
    content: " [spoiler start] ";
}
spoiler::after {
    content: " [spoiler end] ";
}


////////////////////////
// Cookie consent

#cookie-notice {
    padding: 0.5rem 1rem; 
    display: none; 
    text-align: center;
    position: fixed;
    bottom: 0;
    width: calc(100% - 2rem);
    background: #222; 
    color: rgba(255,255,255,0.8);
}
#cookie-notice a,
#cookie-notice a:link,
#cookie-notice a:active,
#cookie-notice a:visited {
    display: inline-block;
    cursor: pointer;
    margin-left: 0.5rem;
    color: $theme-darkgold;
}
#cookie-notice a:hover {
    color: $theme-gold;
    text-decoration: underline;
}

@media (max-width: 767px) {
    #cookie-notice span {
        display: block;
        padding-top: 3px;
        margin-bottom: 1rem;
    }
    #cookie-notice a {
        position: relative;
        bottom: 4px;
    }
}

////////////////////////
// Main header

.main-head .title a {
    float: left;
    margin-top: -25px;
    margin-right: 25px;
}

.main-head .title h1 {
    text-align: left;
    color: $theme-lightgold;
    font-weight: 700;
    -webkit-font-smoothing: antialiased;
    line-height: 1.35;
    letter-spacing: 0;
    filter: drop-shadow(2px 2px 10px #111);
    padding: 5px;
    margin: 0;
}

.main-head .title h2 {
    text-align: left;
    font-size: 18px;
    color: white;
    font-weight: 700;
    -webkit-font-smoothing: antialiased;
    filter: drop-shadow(3px 3px 10px #111);
    line-height: 1.35;
    letter-spacing: 0;
}



#bookmetadatawidget .wp-post-image img,
.book-cover {
    border-radius: 0 6% 6% 0/4%;
    overflow: hidden;
    box-shadow: 4px 4px 12px $theme-lightgray;
}

.main-head .social-icons a:hover {
    color: $theme-lightgold !important;
}

blockquote {
    font-family: 'Source Serif Pro';
    font-size: 1.15em;
}